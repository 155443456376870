<template>
  <div class="content-center">
    <div class="vx-row w-full mb-base c-center flex items-center justify-around">
      <div class="md:w-2/5 w-full flex">
        <vx-card title="Add Denomination">
          <div class="vx-row">
            <div class="vx-col w-full mt-10 mb-6">
              <vx-input-group class="mb-base">
                <template slot="prepend">
                  <div class="prepend-text bg-primary">
                    <span>{{ $t('DenominationAmount') }}</span>
                  </div>
                </template>

                <vs-input :disabled="true" autofocus :placeholder="$t('Amount') + '*'" v-model="denominationAmount" />
                <template slot="append">
                  <div class="append-text bg-primary">
                    <span>€</span>
                  </div>
                </template>
              </vx-input-group>

              <vs-input class="mt-4" :placeholder="$t('Description')" v-model="description" />
            </div>
          </div>
          <div class="vx-row ml-3">
            <vs-button :disabled="!validateForm" class="" @click="addDenomination">{{ $t('Update') }}</vs-button>
            <div class="p-3"></div>
            <vs-button class="" type="border" @click="closeDenomination">{{ $t('Cancel') }}</vs-button>
          </div>
        </vx-card>
      </div>
      <div class="md:w-2/5 w-full flex">

        <vx-card>
          <div class="vx-col w-full">
            <div class="c-center c-center-text">
              <img class="p-image" :src="productImage" alt="">
              <h1 class="denomination-text">{{denominationAmount | germanNumberFormat}}</h1>
            </div>
          </div>

        </vx-card>
      </div>
    </div>

  </div>
</template>

<script>
import * as Vibrant from 'node-vibrant'
import VxModal from "@/layouts/components/custom/VxModal";

export default {
  name: 'EditDenomination',
  components: {VxModal},
  computed: {
    validateForm() {
      return !this.errors.any() && this.denominationAmount !== ''
    },
  },
  data() {
    return {
      denominationAmount: '',
      configurationId: '',
      description: '',
    }
  },
  props: {
    productImage: String,
    productId: String | Number,
    configData: String,
  },
  mounted() {
    let j = JSON.parse(atob(this.configData))
    this.configurationId = j.product_configuration_id;
    this.denominationAmount = j.value;
      this.description = j.info || '';
    // Vibrant.from(this.$refs.pImage).getPalette()
    //   .then((palette) => console.log(palette))
  },
  methods: {
    closeDenomination() {
      this.$emit('denomination-cancel')
    },
    addDenomination() {
      if (!this.denominationAmount || this.denominationAmount === '' || Number(this.denominationAmount) <= 0) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: $t('DenominationAmountError'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      this.submitDenomination();
      // this.$vs.dialog({
      //   color: 'primary',
      //   type: 'confirm',
      //   title: 'Confirmation',
      //   text: `Do you want to add Denomination value ${this.denominationAmount}`,
      //   accept: this.submitDenomination,
      // })
    },
    submitDenomination() {
      const payload = {
        "is_configuration": "1",
        "configuration_id": this.configurationId,
        "info": this.description,
      }
      this.$vs.loading()

      this.$store.dispatch('products/updateProductData', payload).then((data) => {
        this.$vs.loading.close()
        this.isProductAdded = true
        this.$vs.notify({
          position: 'top-center',
          title: 'Success',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
        })
        this.$emit('denomination-added')
      }).catch((error) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
    },
  },
}
</script>

<style scoped>
  .c-center {
    margin: auto;

  }

  .c-center-text {
    text-align: center;
  }

  .p-image {
    width: 90%;
    object-fit: cover;
    max-height: 200px;
    margin: 1rem;
  }
  .vx-input-group .vx-input-group-default .vs-input {
    width: 100px;
  }
  .denomination-text {
    font-size: 60px;
    font-weight: 900;
  }
  .vx-input-group-default.flex-grow {
    flex-grow: 0 !important;
  }
</style>
