<template>
  <div><!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'Products'">
    </breadcrumb-base>
    <transition name="fade">
      <add-denomination :product-image="productImageWebUrl" :product-id="productId"
                        v-if="showDenominationsScreen"
                        @denomination-cancel="cancelDenomination"
                        @denomination-added="denominationAdded"></add-denomination>
    </transition>
    <transition name="fade">
      <edit-denomination :product-image="productImageWebUrl"
                        :product-id="productId"
                         :config-data="selectedConfig"
                        v-if="showEditDenominationsScreen"
                        @denomination-cancel="showEditDenominationsScreen = false"
                        @denomination-added="denominationAdded">

      </edit-denomination>
    </transition>
    <transition name="fade">
      <div class="content-center" v-if="!showDenominationsScreen">
        <div class="vx-col md:w-4/5 w-full mb-base c-center" v-if="!showEditDenominationsScreen">
          <vx-card :title="$t('ViewProduct')">
            <div class="vx-row">
              <div class="vx-col md:w-3/5 w-full">
                <div class="vx-row mb-2">
                  <div class="vx-col w-full">
                    <vs-input
                      :disabled="isProductAdded"
                      v-validate="'required'"
                      validate-on="change"
                      :name="$t('ProductName')"
                      class="w-full" :label-placeholder="$t('ProductName')"
                      v-model="productName"/>
                    <span class="text-danger text-sm">{{ errors.first($t('ProductName')) }}</span>
                  </div>
                </div>
                <div class="vx-row mb-2">
                  <div class="vx-col md:w-1/2 w-full">
                    <vs-input
                      :disabled="isProductAdded"
                      v-validate="'required'"
                      validate-on="change"
                      :name="$t('PinLength')"
                      class="w-full" type="number" :label-placeholder="$t('PinLength')"
                      v-model="pinLength"/>
                    <span class="text-danger text-sm">{{ errors.first($t('PinLength')) }}</span>
                  </div>
                  <div class="vx-row md:w-1/2 w-full">
                    <p class="p-type">{{ $t('ProductType') }}</p>
                    <div class="vx-col w-full p-type-select">
                      <v-select
                        :disabled="isProductAdded" label="name" :options="productTypes"
                        v-model="productType" :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
                    </div>
                  </div>
                </div>
                <div class="vx-row mb-2">
                  <div class="vx-col w-full">
                    <vs-input
                      :disabled="isProductAdded"
                      v-validate="'required'"
                      validate-on="change"
                      :name="$t('CustomerCareNumber')"
                      class="w-full" :label-placeholder="$t('CustomerCareNumber')"
                      v-model="customerCareNumber"/>
                    <span
                      class="text-danger text-sm">{{ errors.first($t('CustomerCareNumber')) }}</span>
                  </div>
                </div>
                
                <!--QR Code Instructions-->
                <div class="vx-row mb-2">
                  <div class="vx-col w-full">
                    <vs-input
                      :name="$t('QrCodeinstructions')"
                      class="w-full" :label-placeholder="$t('QRCodeInstructions')"
                      v-model="qrcodeinstructions"/>
                  </div>
                </div>


                <div class="vx-row mb-6 mt-6">
                  <div class="vx-col w-full">
                    <vs-textarea
                      :label="$t('Instructions')"
                      v-model="instructions"/>
                  </div>
                </div>
              </div>
              <!-- Product Side -->
              <div class="vx-col md:w-2/5 w-full">
                <!-- Product Image -->
                <div class="c-center">
                  <img class="p-image" :src="productImageSrc || productImageSrc" alt="">
                </div>
                <div class="c-center" v-if="">
                  <div class="upload-btn-wrapper">
                    <div class="vx-row upload-contents">
                      <feather-icon icon="PlusIcon" svgClasses=""/>
                      <p class="pl-4">{{!isProductAdded ? $t('AddProductImage') : $t('UpdateProductImage')}}</p>
                    </div>
                    <input type="file" name="myImage" accept="image/*" ref="fileUpload"
                           @change="selectedNewFile"/>
                  </div>
                </div>
                <div class="m-2"></div>
                <div v-if="isProductAdded">
                  <h2 class="px-3 pt-3">Denominations</h2>
                  <div class="c-center pt-3" v-if="productType.key === 'calling card' && denominations.length === 0">
                    <div class="upload-btn-wrapper">
                      <a @click="openDenominations"
                         class="upload-contents  flex items-center justify-center">
                        <div class="vx-row flex">
                          <feather-icon icon="PlusIcon" svgClasses=""/>
                          <p class="pl-4">{{$t('AddDenomination')}}</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="c-center pt-3" v-if="productType.key !== 'calling card'">
                    <div class="upload-btn-wrapper">
                      <a @click="openDenominations"
                         class="upload-contents  flex items-center justify-center">
                        <div class="vx-row flex">
                          <feather-icon icon="PlusIcon" svgClasses=""/>
                          <p class="pl-4">{{$t('AddDenomination')}}</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="c-center vx-col m-3 pt-1" v-if="denominations.length > 0">
                    <div class="mt-3" v-for="(d, index) in denominations">
                      <div @click="openEditDenominations(configs[index])"
                        class="vx-row upload-contents upload-contents-denomination py-3">
                        <div class="flex flex-col items-start px-2">
                          <div class="flex w-full justify-around ">
                            <img class="p-image-denomination flex" :src="productImageSrc || productImageWebUrl" alt="">
                            <h1 class="flex">{{d | germanNumberFormat}}</h1>
                          </div>
                          <p v-if="configs[index].info" class="px-2 text-left font-normal text-xl py-2">{{ $t('Description') }}: {{ configs[index].info }}</p>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="vx-row mt-6">
              <vs-button v-if="!isProductAdded" class="ml-4" :disabled="!validateForm"
                         @click="addProductAndForward">{{ $t('SaveAddDenominations') }}
              </vs-button>
              <vs-button v-if="isProductAdded" class="ml-4" @click="updateProduct">{{ $t('Update') }}
              </vs-button>
              <vs-button v-if="isProductAdded" class="ml-4" @click="doneGoList">{{ $t('Close') }}
              </vs-button>
            </div>
            <div class="m-4"></div>

          </vx-card>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import AddDenomination from './AddDenomination'
import EditDenomination from "@/views/pages/superadmin/products/EditDenomination";

export default {
  name: 'EditProducts',
  components: {
    EditDenomination,
    'v-select': vSelect,
    AddDenomination,
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.productName !== '' && this.pinLength !== '' && this.customerCareNumber !== '' && this.instructions !== '' && this.productType !== '' && this.productImage !== null
    },
  },
  data() {
    return {
      productName: '',
      pinLength: '',
      customerCareNumber: '',
      instructions: '',
      qrcodeinstructions: '',
      productType: '',
      productImage: null,
      productImageWebUrl: '',
      productTypes: [
        {
          'name': 'Cash Cards',
          'key': 'cash card',
        },
        {
          'name': 'Gift Cards',
          'key': 'gift card',
        },
        {
          'name': 'Calling Cards',
          'key': 'calling card',
        },
      ],
      productImageSrc: '',
      isProductAdded: true,
      denominations: [],
      configs: [],
      showDenominationsScreen: false,
      showEditDenominationsScreen: false,
      selectedConfig: '',
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Products', i18n: 'Products', url: '/products/view' },
        { title: 'View Product', i18n: 'ViewProduct', active: true },
      ],
    }
  },
  props: {
    productId: {
      type: String | Number,
      default: '',
    },
  },
  methods: {
    async selectedNewFile(e) {
      this.productImage = this.$refs.fileUpload.files[0]
      const readURL = (file) => new Promise((res, rej) => {
        const reader = new FileReader()
        reader.onload = (e) => res(e.target.result)
        reader.onerror = (e) => rej(e)
        reader.readAsDataURL(file)
      })

      this.productImageSrc = await readURL(this.productImage)

      if(this.isProductAdded) {
        this.updateProductImage();
      }
    },
    updateProductImage() {
      const payload = {
        productImage: this.productImage,
        productId: this.productId
      }
      this.$vs.loading()

      this.$store.dispatch('products/updateProductImage', payload).then((data) => {
        this.$vs.loading.close()
        this.isProductAdded = true
        this.$vs.notify({
          position: 'top-center',
          title: 'Success',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
        })
        this.isProductAdded = true
      }).catch((error) => {
        this.$vs.loading.close()
        this.productImageSrc = null
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
    },
    addProductAndForward() {
      const payload = {
        productName: this.productName,
        productType: this.productType.key,
        pinLength: this.pinLength,
        customerCareNumber: this.customerCareNumber,
        instructions: this.instructions,
        qrcodeinstructions: this.qrcodeinstructions,
        productImage: this.productImage,
      }
      this.$vs.loading()

      this.$store.dispatch('products/addNewProduct', payload).then((data) => {
        this.$vs.loading.close()
        this.isProductAdded = true
        this.$vs.notify({
          position: 'top-center',
          title: 'Success',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
        })
        this.productImageWebUrl = data.data.product_image
        this.productId = data.data.product_id

        this.isProductAdded = true
      }).catch((error) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
    },
    updateProduct() {
      let payload = {
        "product_id": this.productId,
        "instructions": this.instructions,
        "qr_code_pattern": this.qrcodeinstructions,
      }
      this.$store.dispatch('products/updateProductData', payload).then((data) => {
        this.$vs.loading.close()
        this.isProductAdded = true
        this.$vs.notify({
          position: 'top-center',
          title: 'Success',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
        })

        this.fetchProduct();
      }).catch((error) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
    },
    doneGoList() {
      if (this.denominations.length === 0) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please add at least one denomination',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      this.$router.push({ name: 'products-view' })
    },
    openDenominations() {
      this.showDenominationsScreen = true
    },
    openEditDenominations(config) {
      this.selectedConfig = btoa(JSON.stringify(config))
      this.showEditDenominationsScreen = true
    },
    denominationAdded(value) {
      if(value) {
        this.denominations.push(value)
      } else {
        this.fetchProduct()
      }
      this.showDenominationsScreen = false
      this.showEditDenominationsScreen = false
    },
    cancelDenomination() {
      this.showDenominationsScreen = false
    },
    titleCase(value, replacer = '_') {
      value = value.toString()

      const arr = value.split(replacer)
      const capitalized_array = []
      arr.forEach((word) => {
        const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
        capitalized_array.push(capitalized)
      })
      return capitalized_array.join(' ')
    },

    fetchProduct() {
      if (this.productId !== '') {
        this.$vs.loading()
        return this.$store.dispatch('products/fetchSingleProduct', this.productId)
          .then((data) => {
            this.productName = data.product_name
            this.productType = {
              key: data.product_type,
              name: this.titleCase(data.product_type),
            }
            this.pinLength = data.pin_length
            this.customerCareNumber = data.customer_care
            this.instructions = data.instructions
            this.qrcodeinstructions = data.qr_code_pattern
            this.productImageWebUrl = data.product_image
            this.productImageSrc = data.product_image
            this.configs = []
            this.denominations = []
            data.configurations.forEach((conf) => {
              this.denominations.push(conf.value)
              this.configs.push(conf)
            })
            this.$vs.loading.close()
            this.errorFetching = false
          })
          .catch((error) => {
            console.error(error)
            this.$vs.loading.close()
            this.errorFetching = true
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: error.response.data.message || error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          })
      }
    }
  },
  mounted() {
    this.fetchProduct();
  },
}
</script>

<style scoped>
  .c-center {
    margin: auto;
  }

  .p-type {
    padding: 0 1rem;
    font-size: 10px;
  }

  .p-type-select {
    padding: 0 0 0 1rem;
  }

  .upload-btn-wrapper {
    margin: 1rem;
    position: relative;
    display: flex;
  }

  .upload-contents {
    border: 1px dashed #00000040;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
    color: #2C2C2C;
    background-color: white;
    padding: 30px 30px;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    margin: auto;
  }

  .upload-btn-wrapper-denomination {
    position: absolute;
  }

  .upload-contents-denomination {
    padding: 5px;
  }

  .upload-btn-wrapper input[type=file] {
    position: absolute;
    margin: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }

  .p-image {
    width: 90%;
    object-fit: cover;
    max-height: 200px;
    margin: 1rem;
  }

  .p-image-denomination {
    width: 50%;
  }
</style>
